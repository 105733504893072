import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faGithub,
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faCode } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import MySignature from "../assets/img/my_signature.png";
const Footer = ({ darkMode }) => {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer
      className={`footer pt-8 shadow-lg ${
        darkMode ? "bg-gray-700 text-gray-300 " : "bg-gray-200/95 text-gray-800"
      }`}
    >
      <div className="container   lg:mx-32 px-4">
        {/* Main Footer Content */}
        <div className="grid w-full mx-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-8">
          <div
            className="flex  items-center justify-center lg:justify-start lg:items-start gap-2 cursor-pointer "
            onClick={() => handleNavigation("/")}
          >
            <div className="flex flex-col items-center gap-2">
              <div className="flex items-center gap-2">
                <FontAwesomeIcon
                  icon={faCode}
                  className={`lg:text-xl text-md ${
                    darkMode ? "text-gray-200" : "text-gray-800"
                  }`}
                />
                <h1
                  className={`font-extrabold lg:text-xl text-md tracking-wider uppercase ${
                    darkMode
                      ? "bg-gradient-to-r from-gray-200 to-gray-400 text-transparent bg-clip-text"
                      : "bg-gradient-to-r from-black to-gray-400 text-transparent bg-clip-text"
                  }`}
                >
                  Bhishan sah
                </h1>
              </div>
              <img
                src={MySignature}
                alt="My Signature"
                className={`w-fit h-16 -rotate-12 mt-8 select-none ${
                  darkMode
                    ? "brightness-0 invert"
                    : "brightness-200  contrast-200"
                }`}
              />
            </div>
          </div>

          {/* Contact Information */}
          <div className="text-center lg:text-left">
            <h3
              className={`text-xl font-bold ${
                darkMode
                  ? "bg-gradient-to-r from-gray-200 to-gray-400 text-transparent bg-clip-text"
                  : "bg-gradient-to-r from-black to-gray-400 text-transparent bg-clip-text"
              }`}
            >
              Contact Info
            </h3>
            <div
              className={`h-0.5 w-20 mx-auto lg:mx-0 mb-4  rounded-full ${
                darkMode ? "bg-blue-400" : "bg-blue-600"
              }`}
            ></div>
            <div className="space-y-3">
              <p className="flex items-center justify-center lg:justify-start">
                <FontAwesomeIcon
                  icon={faPhone}
                  className={`mr-3 ${
                    darkMode ? "text-blue-400" : "text-blue-600"
                  }`}
                />
                <a
                  href="tel:+919728161275"
                  className="hover:underline transition-colors duration-300"
                >
                  +91-9728161275
                </a>
              </p>
              <p className="flex items-center justify-center lg:justify-start">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className={`mr-3 ${
                    darkMode ? "text-blue-400" : "text-blue-600"
                  }`}
                />
                <a
                  href="mailto:bhishansah@gmail.com"
                  className="hover:underline transition-colors duration-300"
                >
                  bhishansah@gmail.com
                </a>
              </p>
              <span className="flex">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className={` mr-4 mt-1 ${
                    darkMode ? "text-blue-400" : "text-blue-600"
                  }`}
                />
                <p className="flex items-center justify-center lg:justify-start">
                  Village-Rauthat, R.M Balan-bihul-6, Saptari Nepal.
                </p>
              </span>
            </div>
          </div>

          {/* Social Links */}
          <div className="text-center lg:text-left">
            <h3
              className={`text-xl font-bold  ${
                darkMode
                  ? "bg-gradient-to-r from-gray-200 to-gray-400 text-transparent bg-clip-text"
                  : "bg-gradient-to-r from-black to-gray-400 text-transparent bg-clip-text"
              }`}
            >
              Connect With Me
            </h3>
            <div
              className={`h-0.5 w-20 mx-auto lg:mx-0 mb-4 rounded-full ${
                darkMode ? "bg-blue-400" : "bg-blue-600"
              }`}
            ></div>
            <div className="flex justify-center lg:justify-start space-x-4">
              <a
                href="https://wa.me/919728161275?text=Hello!%20How%20are%20you."
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
              >
                <FontAwesomeIcon
                  icon={faWhatsapp}
                  className="text-green-500 w-6 h-6"
                />
              </a>
              <a
                href="https://www.instagram.com/bhishansah/"
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  className="text-pink-500 w-6 h-6"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/bhishansah/"
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  className="text-blue-600 w-6 h-6"
                />
              </a>
              <a
                href="https://github.com/visionmax01"
                target="_blank"
                rel="noopener noreferrer"
                className="transform hover:scale-110 transition-transform duration-300"
              >
                <FontAwesomeIcon
                  icon={faGithub}
                  className={`w-6 h-6 ${
                    darkMode ? "text-gray-300" : "text-gray-800"
                  }`}
                />
              </a>
              <a
                href="https://www.facebook.com/share/qJntLSmzjo3uB8HV/?mibextid=qi2Omg"
                target="_blank"
                rel="noopener noreferrer"
                className={`transform hover:scale-110 transition-transform duration-300`}
              >
                <FontAwesomeIcon
                  icon={faFacebook}
                  className={`w-6 h-6 ${darkMode ? 'text-blue-400' : 'text-blue-800'}`}
                />
              </a>
            </div>
          </div>
        </div>

        

        
      </div>
      {/* Divider */}
      <div
          className={` border-t w-full ${
            darkMode
              ? "bg-gray-700 border-gray-600 "
              : "bg-gray-300 border-gray-300 "
          } `}
        ></div>
      {/* Copyright */}
      <div className="text-center py-3">
          <p
            className={`text-sm ${
              darkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            &copy; {currentYear} Bhishan prasad Sah - All rights reserved.
          </p>
        </div>
    </footer>
  );
};

export default Footer;
