import { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../utils/api.js';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCheck, faEnvelope, faLock, faKey } from '@fortawesome/free-solid-svg-icons';

const ResetPassword = ({ darkMode }) => {
    const [data, setData] = useState({ email: '', otp: '', password: '' });
    const [emailSent, setEmailSent] = useState('');
    const [error, setError] = useState(null);
    const [otpVerified, setOtpVerified] = useState(false);
    const [otpError, setOtpError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const otpInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        document.title = "Reset Password | Bhishan Sah - Software Engineer";
        
        document.querySelector('meta[name="description"]').setAttribute(
            "content", 
            "Securely reset your password for Bhishan Sah's web development services. Quick and easy password recovery process."
        );
        
        document.querySelector('meta[name="keywords"]').setAttribute(
            "content", 
            "reset password, password recovery, account security, Bhishan Sah portal, forgot password"
        );

        document.querySelector('meta[property="og:title"]').setAttribute(
            "content", 
            "Reset Password | Bhishan Sah - Web Development Services"
        );
        
        document.querySelector('meta[property="og:description"]').setAttribute(
            "content", 
            "Secure password reset portal for Bhishan Sah's web development services. Reset your password with OTP verification."
        );

        document.querySelector('meta[name="twitter:title"]').setAttribute(
            "content", 
            "Reset Password | Bhishan Sah - Web Development Services"
        );
        
        document.querySelector('meta[name="twitter:description"]').setAttribute(
            "content", 
            "Secure password reset portal for Bhishan Sah's web development services. Reset your password with OTP verification."
        );

        if (location.state && location.state.email) {
            setData((prevData) => ({ ...prevData, email: location.state.email }));
            setEmailSent(location.state.email);
        }
    }, [location.state]);

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setOtpError(false);
    };

    const verifyOtp = async () => {
        try {
            const response = await api.post('/auth/verify-otp', { email: data.email, otp: data.otp });
            if (response.status === 200) {
                setOtpVerified(true);
                toast.success('OTP verified successfully');
            } else {
                throw new Error('OTP verification failed');
            }
        } catch (error) {
            setOtpVerified(false);
            setOtpError(true);
            otpInputRef.current.focus();
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        if (!otpVerified) {
            await verifyOtp();
            return;
        }

        try {
            const response = await api.post('/auth/reset-password', { email: data.email, password: data.password });
            if (response.status === 200) {
                toast.success('Password reset successful');
                navigate('/login');
            } else {
                throw new Error('Unexpected response from server');
            }
        } catch (error) {
            setError(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className={`flex items-center justify-center px-4 lg:px-0    ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
            <div className={`w-full max-w-md p-8 space-y-8 rounded-xl shadow-2xl ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                <h1 className={`text-3xl font-extrabold text-center ${darkMode ? 'text-white' : 'text-gray-900'}`}>Reset Your Password</h1>
                {emailSent && <p className={`text-center ${darkMode ? 'text-gray-300' : 'text-gray-600'}`}>OTP has been sent to: <strong>{emailSent}</strong></p>}
                {error && <p className="text-center text-red-500">{error}</p>}

                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                    <div className="rounded-md shadow-sm -space-y-px">
                        <div className="relative">
                            <label htmlFor="email" className="sr-only">Email</label>
                            <FontAwesomeIcon icon={faEnvelope} className={`absolute z-30 left-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            <input
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                disabled
                                value={data.email}
                                onChange={handleChange}
                                className={`appearance-none rounded-t-md relative block w-full px-3 py-2 border ${darkMode ? 'border-gray-700 bg-gray-700 text-gray-300' : 'border-gray-300 text-gray-900'} placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pl-10`}
                                placeholder="Email address"
                            />
                        </div>
                        <div className="relative">
                            <label htmlFor="otp" className="sr-only">OTP</label>
                            <FontAwesomeIcon icon={faKey} className={`absolute left-3 top-1/2 z-30 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            <input
                                id="otp"
                                name="otp"
                                type="text"
                                required
                                ref={otpInputRef}
                                value={data.otp}
                                onChange={handleChange}
                                className={`appearance-none rounded-none relative block w-full px-3 py-2 border ${darkMode ? 'border-gray-700 bg-gray-700 text-gray-300' : 'border-gray-300 text-gray-900'} ${otpError ? 'border-red-500' : ''} placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pl-10`}
                                placeholder="Enter OTP"
                            />
                            {otpVerified && <FontAwesomeIcon icon={faCheck} className="absolute right-3 top-1/2 transform -translate-y-1/2 text-green-500" />}
                            {otpError && <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500 text-sm">Invalid OTP</span>}
                        </div>
                        <div className="relative">
                            <label htmlFor="password" className="sr-only">New Password</label>
                            <FontAwesomeIcon icon={faLock} className={`absolute left-3 top-1/2 z-30 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            <input
                                id="password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="new-password"
                                required
                                disabled={!otpVerified}
                                value={data.password}
                                onChange={handleChange}
                                className={`appearance-none rounded-b-md relative block w-full px-3 py-2 border ${darkMode ? 'border-gray-700 bg-gray-700 text-gray-300' : 'border-gray-300 text-gray-900'} placeholder-gray-500 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm pl-10`}
                                placeholder="New Password"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className={`h-5 w-5 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            </button>
                        </div>
                    </div>

                    <div>
                        <button
                            type="submit"
                            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${darkMode ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                        >
                            {otpVerified ? 'Reset Password' : 'Verify OTP'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ResetPassword;
