import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import mongoose from '../assets/blogsImg/MongoDB-img.webp';
import vite from '../assets/blogsImg/vite-logo.webp';
import tailwind from '../assets/blogsImg/tailwind-Css.webp';
import cloudinary from '../assets/blogsImg/cloudinary-blog-img.webp';
import Footer from '../components/Footer';
import { useBlog } from '../context/BlogContext';

const BlogPosts = ({ darkMode }) => {
  const [loading, setLoading] = useState(true); 
  const [posts, setPosts] = useState([]);
  const [imgLoading, setImgLoading] = useState([]); // Track image loading state for each post
  const { setCurrentPost } = useBlog();

  useEffect(() => {
    // Simulate fetching posts
    const fetchPosts = async () => {
      await new Promise((resolve) => setTimeout(resolve, 0));
      const fetchedPosts = [
        {
          id: 1,
          title: 'Getting Started with MongoDB',
          description: 'An introduction to MongoDB, its features, and how to set it up for your projects.',
          image: mongoose,
          postname: 'Bhishan Sah',
          authorImage: 'https://avatars.githubusercontent.com/u/123456789',
          date: '2024-03-15',
          readingTime: '8 min',
          category: 'Database',
          content: `
            MongoDB is a popular NoSQL database that offers flexibility and scalability for modern applications.

            ## What is MongoDB?
            MongoDB is a document-oriented database that stores data in flexible, JSON-like documents. Unlike traditional relational databases, MongoDB doesn't require a fixed schema, making it perfect for applications that need to evolve quickly.

            ## Key Features
            - Document-oriented storage
            - High scalability
            - Rich query language
            - High performance
            - Free to use (Community Edition)

            ## Getting Started
            1. Install MongoDB Community Edition
            2. Set up your first database
            3. Learn basic CRUD operations
            4. Implement data modeling best practices

            ## Basic Commands
            \`\`\`bash
            # Start MongoDB
            mongod

            # Connect to MongoDB
            mongo

            # Create a new database
            use myDatabase
            \`\`\`

            ## When to Use MongoDB
            - When you need flexible schema design
            - For applications requiring high scalability
            - When dealing with large volumes of unstructured data
            - Real-time analytics and high-speed logging
          `
        },
        {
          id: 2,
          title: 'Why Use React with Vite?',
          description: 'Exploring the benefits of using Vite for React development and how it enhances productivity.',
          image: vite,
          postname: 'Bhishan Sah',
          authorImage: 'https://avatars.githubusercontent.com/u/123456789',
          date: '2024-03-14',
          readingTime: '6 min',
          category: 'Frontend Development',
          content: `
            Vite is a modern build tool that significantly improves the development experience for React applications.

            ## Advantages of Vite
            - Lightning-fast cold server start
            - Instant hot module replacement (HMR)
            - True on-demand compilation
            - Optimized build performance

            ## Setting Up React with Vite
            \`\`\`bash
            npm create vite@latest my-react-app -- --template react
            cd my-react-app
            npm install
            npm run dev
            \`\`\`

            ## Key Features
            1. ES modules for development
            2. Bundling optimization
            3. Built-in TypeScript support
            4. CSS preprocessing support
            
            ## Performance Comparison
            - Faster than Create React App
            - Reduced bundle sizes
            - Improved development server performance
          `
        },
        {
          id: 3,
          title: 'Mastering Tailwind CSS',
          description: 'A comprehensive guide to using Tailwind CSS to build beautiful and responsive web designs.',
          image: tailwind,
          postname: 'Bhishan Sah',
          authorImage: 'https://avatars.githubusercontent.com/u/123456789',
          date: '2024-03-13',
          readingTime: '10 min',
          category: 'CSS',
          content: `
             Getting Started with Tailwind CSS

            Tailwind CSS is a utility-first CSS framework that revolutionizes the way we write CSS. Unlike traditional frameworks like Bootstrap, Tailwind doesn't provide pre-built components. Instead, it gives you low-level utility classes that you can combine to build any design.

            ## Why Choose Tailwind CSS?
            - **Utility-First Approach**: Write styles directly in your HTML/JSX
            - **Highly Customizable**: Easy to adapt to your design system
            - **No Unnecessary Code**: Only includes the styles you actually use
            - **Responsive Design**: Built-in responsive modifiers
            - **Fast Development**: Build custom designs without writing CSS

            ## Installation Steps

            ### 1. Create a New Project (if needed)
            \`\`\`bash
            npm create vite@latest my-project -- --template react
            cd my-project
            \`\`\`

            ### 2. Install Tailwind CSS
            \`\`\`bash
            npm install -D tailwindcss postcss autoprefixer
            npx tailwindcss init -p
            \`\`\`

            ### 3. Configure Tailwind
            Add this to your \`tailwind.config.js\`:
            \`\`\`javascript
            /** @type {import('tailwindcss').Config} */
            export default {
              content: [
                "./index.html",
                "./src/**/*.{js,ts,jsx,tsx}",
              ],
              theme: {
                extend: {},
              },
              plugins: [],
            }
            \`\`\`

            ### 4. Add Tailwind to CSS
            Add these lines to your main CSS file (\`src/index.css\`):
            \`\`\`css
            @tailwind base;
            @tailwind components;
            @tailwind utilities;
            \`\`\`

            ### 5. Start Using Tailwind
            Now you can use Tailwind classes in your components:
            \`\`\`jsx
            function Button() {
              return (
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                  Click me
                </button>
              );
            }
            \`\`\`

            ## Start Your Development Server
            \`\`\`bash
            npm run dev
            \`\`\`

          
          `
        },
        {
          id: 4,
          title: 'Using Cloudinary for Image Management',
          description: 'Learn how to integrate Cloudinary into your application for efficient image storage and manipulation.',
          image: cloudinary,
          postname: 'Bhishan Sah',
          authorImage: 'https://avatars.githubusercontent.com/u/123456789',
          date: '2024-03-12',
          readingTime: '5 min',
          category: 'Image Management',
          content: `
            Cloudinary is a cloud-based image management platform that allows you to store, transform, and deliver images and videos.

            ## Key Features
            - Image storage
            - Image transformation
            - Image delivery
            - Video management
            - API integration

            ## Getting Started
            1. Sign up for Cloudinary
            2. Install Cloudinary SDK
            3. Configure Cloudinary
            4. Use Cloudinary APIs

            ## Basic Commands
            \`\`\`bash
            # Install Cloudinary SDK
            npm install cloudinary

            # Configure Cloudinary
            npx cloudinary init

            # Upload an image
            npx cloudinary upload ./path/to/image.jpg
            \`\`\`

            ## When to Use Cloudinary
            - When you need a reliable image management platform
            - For applications requiring high scalability
            - When dealing with large volumes of images
            - Real-time image processing and manipulation
          `
        },
      ];
      setPosts(fetchedPosts);
      setImgLoading(Array(fetchedPosts.length).fill(true)); // Initialize loading state for each image
      setLoading(false); // Set loading to false after posts are fetched
    };

    fetchPosts();
  }, []);

  const handleImageLoad = (index) => {
    setImgLoading((prev) => {
      const newLoading = [...prev];
      newLoading[index] = false; // Set specific image to loaded
      return newLoading;
    });
  };

  // Loading sketch placeholder
  const LoadingSketch = () => (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {[...Array(4)].map((_, index) => (
        <article key={index} className={`rounded-lg shadow-lg overflow-hidden bg-gray-300 animate-pulse`}>
          <div className="h-48 bg-gray-400" />
          <div className="p-4">
            <div className="h-6 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 mb-2" />
            <div className="h-4 bg-gray-400 w-1/2 " />
          </div>
        </article>
      ))}
    </div>
  );

  useEffect(() => {
    // Set page title
    document.title = "Tech News Blog | Latest in Web Development and Technology";
    
    // Set meta tags
    document.querySelector('meta[name="description"]').setAttribute(
      "content", 
      "Stay updated with the latest tech news, tutorials, and insights on web development, databases, frontend frameworks, and image management."
    );
    
    document.querySelector('meta[name="keywords"]').setAttribute(
      "content", 
      "tech news, web development, MongoDB, React, Vite, Tailwind CSS, Cloudinary, frontend, database, image management"
    );
  }, []);

  return (
    <>
      <main className={`container ${darkMode ? 'text-white' : 'text-gray-900'}`}>
        <h1 className="text-3xl font-bold text-center mb-6">Tech News</h1>
        <div className="lg:w-3/4 pb-6 w-[85%] mx-auto">
          {loading ? (
            <LoadingSketch />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {posts.map((post, index) => (
                <article key={post.id} className={`rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 ${darkMode ? 'bg-black/50 shadow-white/10' : 'bg-white'}`}>
                  {imgLoading[index] && (
                    <div className="h-48 bg-gray-400 animate-pulse" /> // Placeholder while loading
                  )}
                  <img
                    src={post.image}
                    alt={post.title}
                    className={`w-full h-48 object-fit transition-opacity duration-300 ${imgLoading[index] ? 'opacity-0' : 'opacity-100'}`}
                    onLoad={() => handleImageLoad(index)} // Image loaded
                    onError={() => handleImageLoad(index)} // Handle error (optional)
                  />
                  <div className="p-4">
                    <div className="flex items-center space-x-4 mb-3">
                      <img 
                        src={post.authorImage} 
                        alt={post.postname}
                        className="w-8 h-8 rounded-full"
                      />
                      <div>
                        <p className="text-sm font-medium">{post.postname}</p>
                        <p className="text-xs text-gray-500">{new Date(post.date).toLocaleDateString()}</p>
                      </div>
                    </div>
                    <h2 className="text-xl font-semibold hover:text-blue-500 transition-colors">{post.title}</h2>
                    <p className="mt-2 text-gray-600 dark:text-gray-300">{post.description}</p>
                    <div className="mt-4 flex items-center justify-between">
                      <span className="text-sm text-blue-500">{post.category}</span>
                      <span className="text-sm text-gray-500">{post.readingTime} read</span>
                    </div>
                    <Link 
                      to={`/single-blog-view/${post.id}`} 
                      className="text-blue-600 hover:underline mt-3 inline-block"
                      onClick={() => setCurrentPost(post)}
                    >
                      Read More
                    </Link>
                  </div>
                </article>
              ))}
            </div>
          )}
        </div>
        <Footer  darkMode={darkMode}/>
      </main>
    </>
  );
};

export default BlogPosts;
