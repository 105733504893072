import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub, faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import Api from '../utils/api.js';
import toast from 'react-hot-toast';

const ContactPage = ({ darkMode }) => {
    const [loading, setLoading] = useState(false); // State to manage loading
    const variants = {
        hidden: { opacity: 0, y: -100 },
        visible: { opacity: 1, y: 0 },
    };

    useEffect(() => {
        // Set page title
        document.title = "Contact | Bhishan Sah - Software Engineer";
        
        // Set meta tags
        document.querySelector('meta[name="description"]').setAttribute(
            "content", 
            "Get in touch with Bhishan Sah, a professional web developer from Nepal. Available for freelance projects, collaborations, and opportunities. Contact via phone, email, or social media."
        );
        
        document.querySelector('meta[name="keywords"]').setAttribute(
            "content", 
            "contact Bhishan Sah, hire web developer, Nepal web developer, freelance developer, React developer contact, full stack developer contact"
        );

        // Update Open Graph tags for better social sharing
        document.querySelector('meta[property="og:title"]').setAttribute(
            "content", 
            "Contact Bhishan Sah | Web Developer & Software Engineer"
        );
        
        document.querySelector('meta[property="og:description"]').setAttribute(
            "content", 
            "Connect with Bhishan Sah for web development projects, collaborations, and professional opportunities. Based in Nepal, available worldwide."
        );

        // Update Twitter Card tags
        document.querySelector('meta[name="twitter:title"]').setAttribute(
            "content", 
            "Contact Bhishan Sah | Web Developer & Software Engineer"
        );
        
        document.querySelector('meta[name="twitter:description"]').setAttribute(
            "content", 
            "Connect with Bhishan Sah for web development projects, collaborations, and professional opportunities. Based in Nepal, available worldwide."
        );
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {
            name: formData.get('name'),
            email: formData.get('email'),
            phone: formData.get('phone'),
            message: formData.get('message'),
        };

        setLoading(true); // Set loading to true

        try {
            const response = await Api.post('/contacts', data);

            if (response.status === 201) {
                toast.success('Message sent successfully!');
                event.target.reset(); // Clear the form
            } else {
                alert('Failed to send message.');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        } finally {
            setLoading(false); // Set loading to false after submission
        }
    };

    return (
         <motion.div
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
            variants={variants}
            className={`select-none ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-black'}  my-auto`}
        >
            <div className="text-3xl font-bold flex-col flex justify-center items-center px-6 pb-6 lg:pb-0 lg:pt-4">
                <h1 className="lg:text-[28px] text-sm uppercase lg:bg-blue-800/60 lg:p-2 w-1/2 text-center rounded-md lg:rounded-t-full">Contact Me</h1>
                <p className={`w-20 h-0.5 block sm:hidden rounded-lg ${darkMode ? 'bg-blue-700' : 'bg-blue-800'}`}></p>
            </div>
            <div className={`lg:w-3/4 w-full mx-auto rounded-lg shadow-lg lg:px-4 lg:py-6 ${darkMode ? 'lg:bg-color-black shadow-white/10 lg:border-t-2 lg:border-blue-800/60' : 'outline-none focus:border-black bg-gray-100/85 shadow-black/20 lg:border-t-2 lg:border-blue-800/60'}`}>
                <div className="flex flex-col md:flex-row">
                    <div className={`md:w-1/2 flex flex-col text-center justify-center items-center md:pr-4 mb-6 md:mb-0 ${darkMode ? 'lg:text-gray-300 text-gray-300/85' : 'lg:text-black text-black/65'}`}>
                        <h2 className="text-2xl font-semibold ">Personal Information</h2>
                        <p className={`w-24 h-0.5 hidden sm:block rounded-lg mb-3 ${darkMode ? 'bg-blue-700' : 'bg-black/70'}`}></p>
                        <p className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                            <a href="tel:+919728161275" className="hover:underline">+91-9728161275</a>
                        </p>
                        <p className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                            <a href="mailto:bhishansah@gmail.com" className="hover:underline">bhishansah@gmail.com</a>
                        </p>
                        <p className="mb-4 mx-8">Village-Rauthat, R.M Balan-bihul-6, Saptari Nepal.</p>
                        <h3 className="text-xl font-semibold mb-2">Connect with me:</h3>
                        <div className="flex space-x-4">
                            <a 
                                href="https://wa.me/919728161275?text=Hello!%20How%20are%20you." 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="Contact on WhatsApp"
                                title="Contact on WhatsApp"
                            >
                                <FontAwesomeIcon icon={faWhatsapp} className="text-green-500 w-8 h-8" />
                            </a>
                            <a 
                                href="https://www.instagram.com/bhishansah/" 
                                target="_blank" 
                                rel="noopener noreferrer"
                                aria-label="Follow on Instagram"
                                title="Follow on Instagram"
                            >
                                <FontAwesomeIcon icon={faInstagram} className="text-pink-500 w-8 h-8" />
                            </a>
                            <a href="https://www.linkedin.com/in/bhishansah/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="text-blue-600 w-8 h-8" />
                            </a>
                            <a href="https://github.com/visionmax01" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faGithub} className={` w-8 h-8 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`} />
                            </a>
                            <a href="https://www.facebook.com/share/qJntLSmzjo3uB8HV/?mibextid=qi2Omg" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} className={`text-blue-800 w-8 h-8 ${darkMode ? 'text-gray-300' : 'text-blue-800'}`} />
                            </a>
                        </div>
                    </div>

                    <div className={`md:w-1/2 md:pl-4 p-4 pb-8 lg:p-0 lg:bg-transparent rounded-t-[30px] ${darkMode ? 'bg-color-black text-gray-400' : 'bg-gray-400/70 text-black/80'}`}>
                        <div className="pb-3">
                            <h2 className="text-2xl font-semibold">Send a Message!</h2>
                            <p className={`w-24 h-0.5 hidden sm:block rounded-lg ${darkMode ? 'bg-blue-700' : 'bg-black/70'}`}></p>
                        </div>

                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div>
                                <label className="block mb-1">Name</label>
                                <input type="text" name="name" required className={`w-full p-2 rounded border-none ${darkMode ? 'bg-gray-700 focus:border-blue-600 text-white' : 'focus:border-blue-600 bg-gray-100/85 lg:bg-gray-300/70'} focus:outline-none focus:ring-2 focus:ring-blue-600`} />
                            </div>
                            <div>
                                <label className="block mb-1">Email</label>
                                <input type="email" name="email" required className={`w-full p-2 rounded border-none ${darkMode ? 'bg-gray-700 focus:border-blue-600 text-white' : 'focus:border-blue-600 bg-gray-100/85 lg:bg-gray-300/70'} focus:outline-none focus:ring-2 focus:ring-blue-600`} />
                            </div>
                            <div>
                                <label className="block mb-1">Phone Number</label>
                                <input type="tel" name="phone" required className={`w-full p-2 rounded border-none ${darkMode ? 'bg-gray-700 focus:border-blue-600 text-white' : 'focus:border-blue-600 bg-gray-100/85 lg:bg-gray-300/70'} focus:outline-none focus:ring-2 focus:ring-blue-600`} />
                            </div>
                            <div>
                                <label className="block mb-1">Message</label>
                                <textarea name="message" required className={`w-full p-2 rounded border-none ${darkMode ? 'bg-gray-700 focus:border-blue-600 text-white' : 'focus:border-blue-600 bg-gray-100/85 lg:bg-gray-300/70'} focus:outline-none focus:ring-2 focus:ring-blue-600`} />
                            </div>
                            <button type="submit" 
                            className={`w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300 `}
                            disabled={loading}
                            >
                                {loading ? 'Sending...' : 'Send Message'} {/* Loading state */}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ContactPage;
