// src/SinglePost.jsx
import React from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { useBlog } from '../context/BlogContext';
import { FiCopy } from 'react-icons/fi';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

const SinglePost = ({ darkMode }) => {
  const { id } = useParams(); // Get post ID from URL
  const { currentPost, setCurrentPost } = useBlog();
  const navigate = useNavigate();

  useEffect(() => {
    // If no currentPost, try to get from localStorage or fetch from posts
    if (!currentPost) {
      const savedPost = localStorage.getItem(`blog-post-${id}`);
      if (savedPost) {
        setCurrentPost(JSON.parse(savedPost));
      } else {
        // Get posts data from BlogPosts component
        const posts = [
          // Copy the posts array from BlogPosts.jsx
          // This should ideally come from an API or central state management
        ];
        const post = posts.find(p => p.id === parseInt(id));
        if (post) {
          setCurrentPost(post);
          localStorage.setItem(`blog-post-${id}`, JSON.stringify(post));
        } else {
          navigate('/my-blogs');
        }
      }
    } else {
      // Save current post to localStorage
      localStorage.setItem(`blog-post-${id}`, JSON.stringify(currentPost));
    }
  }, [id, currentPost, setCurrentPost, navigate]);

  if (!currentPost) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
    </div>;
  }

  // Add breadcrumb navigation at the top
  const Breadcrumb = () => (
    <nav className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
      <ol className="flex items-center space-x-2 text-gray-500 dark:text-gray-400">
        <li>
          <Link to="/my-blogs" className="hover:text-blue-500">Blog</Link>
        </li>
        <li>
          <span className="mx-2">/</span>
        </li>
        <li className="text-gray-900 dark:text-white font-medium truncate">
          {currentPost.title}
        </li>
      </ol>
    </nav>
  );

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard!');
  };

  const renderContent = (content) => {
    const segments = content.split(/(```[\s\S]*?```|`[\s\S]*?`)/);
    
    return segments.map((segment, index) => {
      // Code block styling
      if (segment.startsWith('```') && segment.endsWith('```')) {
        const codeContent = segment.slice(3, -3);
        const [language, ...codeLines] = codeContent.split('\n');
        const code = language.includes('```') ? codeContent : codeLines.join('\n');
        const lang = language.includes('```') ? '' : language;

        return (
          <div 
            key={index} 
            className="my-8 rounded-lg overflow-hidden shadow-xl"
          >
            <div className="flex justify-between items-center px-4 py-3 bg-gray-900 border-b border-gray-700">
              <span className="text-sm font-medium text-gray-300">{lang || 'Code'}</span>
              <button
                onClick={() => handleCopy(code)}
                className="px-3 py-1.5 rounded-md hover:bg-gray-700 transition-colors duration-200 flex items-center gap-2 text-gray-300 hover:text-white"
                title="Copy code"
              >
                <FiCopy className="w-4 h-4" />
                <span className="text-sm font-medium">Copy</span>
              </button>
            </div>
            <pre className="p-2 bg-black overflow-x-auto">
              <code className={`font-mono text-sm language-${lang.toLowerCase()} text-gray-100`}>
                {code}
              </code>
            </pre>
          </div>
        );
      }
      // Inline code styling
      else if (segment.startsWith('`') && segment.endsWith('`')) {
        const command = segment.slice(1, -1);
        return (
          <div 
            key={index} 
            className=" flex items-center justify-between bg-black rounded-lg py-1 shadow-lg"
          >
            <code className="font-mono pl-5 text-sm text-gray-100">{command}</code>
            <button
              onClick={() => handleCopy(command)}
              className="ml-2 px-3 py-1 rounded-md hover:bg-gray-800 transition-colors duration-200 flex items-center gap-2 text-gray-300 hover:text-white"
              title="Copy command"
            >
              <FiCopy className="w-4 h-4" />
              <span className="text-sm font-medium">Copy</span>
            </button>
          </div>
        );
      }
      return <ReactMarkdown key={index}>{segment}</ReactMarkdown>;
    });
  };

  // Function to calculate reading time
  const calculateReadingTime = (content) => {
    const wordsPerMinute = 200;
    const wordCount = content.split(/\s+/).length;
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  };

  // Function to truncate description
  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength).trim() + '...';
  };

  // Function to format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className={`min-h-screen -mt-8 ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-50 text-gray-900'}`}>
      <Breadcrumb />
      {/* Hero Section with Image */}
      <div className="w-full h-[50vh] relative mb-12">
        <img 
          src={currentPost.image} 
          alt={currentPost.title} 
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-black/40" />
        <div className="absolute bottom-0 left-0 right-0 p-8 max-w-7xl mx-auto">
          <h1 className="text-5xl font-extrabold text-white mb-4 tracking-tight leading-tight">
            {currentPost.title}
          </h1>
          <div className="flex items-center text-gray-200 text-sm space-x-6">
            <span className="font-medium flex items-center">
              <img 
                src={currentPost.authorImage || 'default-avatar.png'} 
                alt={currentPost.postname}
                className="w-6 h-6 rounded-full mr-2"
              />
              {currentPost.postname}
            </span>
            <span className="text-gray-400">
              {new Date(currentPost.date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
          </div>
        </div>
      </div>

      {/* Main Content Layout */}
      <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col lg:flex-row lg:space-x-8">
          {/* Side Navigation - You might want to add a table of contents here */}
          <div className="hidden lg:block lg:w-64 flex-shrink-0">
            <div className="sticky top-6">
              <nav className="space-y-1">
                {/* Add table of contents if needed */}
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 max-w-3xl mx-auto lg:mx-0">
            <article className={`prose ${darkMode ? 'prose-invert' : ''} prose-lg max-w-none prose-headings:font-bold prose-headings:tracking-tight prose-p:leading-relaxed prose-pre:p-0 animate-fade-in`}>
              <p className="text-xl leading-relaxed mb-8 font-medium text-gray-600 dark:text-gray-300 border-l-4 border-blue-500 pl-4 italic">
                {currentPost.description}
              </p>
              <div className="bg-gradient-to-r from-blue-100 to-purple-100 dark:from-blue-900 dark:to-purple-900 p-6 rounded-lg shadow-md">
                <div className="overflow-hidden whitespace-normal break-words">
                  {renderContent(currentPost.content)}
                </div>
              </div>
            </article>

            {/* Author Section */}
            <div className={`mt-16 p-8 rounded-2xl ${
              darkMode 
                ? 'bg-gradient-to-br from-gray-800 to-gray-900 ring-1 ring-white/10' 
                : 'bg-gradient-to-br from-white to-gray-100 ring-1 ring-gray-900/5'
            } shadow-lg transform hover:scale-105 transition-transform duration-300`}>
              <div className="flex items-center mb-6">
                <img 
                  src={currentPost.authorImage || 'default-avatar.png'}
                  alt={currentPost.postname}
                  className="w-16 h-16 rounded-full mr-6 border-4 border-blue-500 shadow-lg"
                />
                <div>
                  <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-500 to-purple-500">About {currentPost.postname}</h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Technical Writer & Developer</p>
                </div>
              </div>
              <p className="text-gray-600 dark:text-gray-300 leading-relaxed text-lg italic">
                "{currentPost.postname} is a technical writer and developer passionate about sharing knowledge and helping others learn new technologies."
              </p>
            </div>
          </div>

          {/* Right Sidebar - Could be used for related content */}
          <div className="hidden xl:block xl:w-64 flex-shrink-0">
            <div className="sticky top-6">
              {/* Add related content or additional navigation */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePost;

