import React, { useState } from 'react';
import { motion } from 'framer-motion';
import api from '../utils/api'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faEye, faTrash, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';

const PAGE_SIZE = 5;

const ContactDataPage = ({ contacts, onClose, onDelete }) => {
    const [selectedContact, setSelectedContact] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [contactsToDelete, setContactsToDelete] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedIds, setSelectedIds] = useState(new Set());

    const isMobile = window.innerWidth < 768; // Mobile breakpoint

    const variants = {
        hidden: { opacity: 0, y: 100 },
        visible: { opacity: 1, y: 0 },
    };

    const handleSelectContact = (contactId) => {
        setSelectedIds((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(contactId)) {
                newSelected.delete(contactId);
            } else {
                newSelected.add(contactId);
            }
            return newSelected;
        });
    };

    const handleDelete = () => {
        setContactsToDelete(Array.from(selectedIds));
        setShowConfirm(true);
    };

    const confirmDelete = async () => {
        setIsDeleting(true);
        setShowConfirm(false);
        try {
            await Promise.all(contactsToDelete.map(id => api.delete(`/auth/contacts/${id}`)));
            toast.success('Contacts deleted successfully!');
            contactsToDelete.forEach(id => onDelete(id)); 
            setSelectedIds(new Set()); // Reset selection
        } catch (error) {
            console.error('Error deleting contacts:', error);
            toast.error('Failed to delete contacts.');
        } finally {
            setIsDeleting(false);
        }
    };

    const handleView = (contact) => {
        setSelectedContact(contact);
    };

    const handleCloseDetail = () => {
        setSelectedContact(null);
    };

    const sortedContacts = [...contacts].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    
    const totalPages = Math.ceil(sortedContacts.length / PAGE_SIZE);
    const displayedContacts = sortedContacts.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);

    return (
        <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-40">
            <motion.div
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={variants}
                transition={{ duration: 0.5 }}
                className="fixed lg:top-12   left-0 right-0 bottom-0 flex justify-center z-50"
            >
                <div className="bg-white text-black relative h-fit rounded-t-[30px] lg:rounded-lg p-6 lg:w-3/4 w-full shadow-md">
                    <h2 className="text-2xl mb-4">Contacts</h2>
                    <button onClick={onClose} className="absolute top-0 right-0 mb-4 p-2 rounded-tr-[30px] bg-red-600 text-white lg:rounded-tr-lg">
                        Close
                    </button>
                    <table className="w-full h-fit border-collapse">
                        <thead>
                            <tr className="text-left">
                                <th className="border p-2"> <FontAwesomeIcon icon={faCaretDown} className="ml-2" /></th>
                                <th className="border p-2">#</th>
                                <th className="border p-2">Name</th>
                                {!isMobile && (
                                    <>
                                        <th className="border p-2">Email</th>
                                        <th className="border p-2">Phone</th>
                                        <th className="border p-2">Created Date</th>
                                    </>
                                )}
                                <th className="border p-2">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedContacts.map((contact, index) => (
                                <tr key={contact._id}>
                                    <td className="border p-2">
                                        <input
                                            type="checkbox"
                                            checked={selectedIds.has(contact._id)}
                                            onChange={() => handleSelectContact(contact._id)}
                                        />
                                    </td>
                                    <td className="border p-2">{(currentPage - 1) * PAGE_SIZE + index + 1}</td>
                                    <td className="border p-2">
                                        <span>
                                            {contact.name}
                                            {getContactStatus(contact.createdAt) === 'New' && (
                                                <span className="text-green-500 font-bold"> - New</span>
                                            )}
                                            {getContactStatus(contact.createdAt) === 'Old' && (
                                                <span className="text-red-500 font-bold"> - Old</span>
                                            )}
                                        </span>
                                    </td>
                                    {!isMobile && (
                                        <>
                                            <td className="border p-2">{contact.email}</td>
                                            <td className="border p-2">{contact.phone}</td>
                                            <td className="border p-2">{new Date(contact.createdAt).toLocaleDateString()}</td>
                                            <td className="border p-2">
                                                <button 
                                                    onClick={() => handleView(contact)} 
                                                    className="text-blue-600 mr-2 py-2 px-4"
                                                >
                                                    View
                                                </button>
                                                <button 
                                                    onClick={() => handleDelete(contact)} 
                                                    className="text-red-600 py-2 px-4"
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </>
                                    )}
                                    {isMobile && (
                                        <td className="border flex justify-center gap-3">
                                            <button 
                                                onClick={() => handleView(contact)} 
                                                className="text-blue-600 mr-2 py-2"
                                            >
                                                 <FontAwesomeIcon icon={faEye} className="mr-1" />
                                            </button>
                                            <button 
                                                onClick={() => handleDelete(contact)} 
                                                className="text-red-600 py-2"
                                            >
                                                <FontAwesomeIcon icon={faTrash} className="mr-1" />
                                            </button>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="flex justify-between mt-4">
                        <button 
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} 
                            disabled={currentPage === 1}
                            className="p-2 bg-gray-300 rounded"
                        >
                            Prev
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button 
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} 
                            disabled={currentPage === totalPages}
                            className="p-2 bg-gray-300 rounded"
                        >
                            Next
                        </button>
                    </div>
                    <button 
                        onClick={handleDelete} 
                        disabled={selectedIds.size === 0}
                        className={`mt-4 p-2 ${selectedIds.size === 0 ? 'bg-gray-300' : 'bg-red-600'} text-white rounded`}
                    >
                        Delete Selected
                    </button>
                </div>
            </motion.div>

            {selectedContact && (
                <ContactDetailPopup contact={selectedContact} onClose={handleCloseDetail} />
            )}

            {showConfirm && (
                <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white mx-2 p-4 rounded-lg shadow-lg">
                        <h2 className="text-xl mb-4 text-black">Are you sure you want to delete these contacts?</h2>
                        <div className="flex justify-end text-black">
                            <button 
                                onClick={() => setShowConfirm(false)} 
                                className="mr-2 p-2 bg-gray-300 rounded"
                            >
                                No
                            </button>
                            <button 
                                onClick={confirmDelete} 
                                className={`p-2 ${isDeleting ? 'bg-gray-400' : 'bg-red-600'} text-white rounded`}
                                disabled={isDeleting}
                            >
                                {isDeleting ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const getContactStatus = (createdAt) => {
    const messageDate = new Date(createdAt);
    const now = new Date();
    const timeDiff = now - messageDate;
    return timeDiff < 2 * 24 * 60 * 60 * 1000 ? 'New' : 'Old'; // New if within 2 days
};

const ContactDetailPopup = ({ contact, onClose }) => {
    const variants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: { opacity: 1, scale: 1 },
    };

    return (
        <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={variants}
            transition={{ duration: 0.3 }}
            className="fixed flex items-center text-black justify-center top-0 left-0 right-0 bottom-0 z-50"
        >
            <div className="bg-gray-200 rounded-lg p-6 h-1/2 lg:w-3/4 max-w-lg shadow-lg relative">
                <h2 className="text-2xl mb-4">Contact Details</h2>
                <button onClick={onClose} className="absolute top-2 right-2 p-2 bg-red-600 text-white rounded-md">
                    Close
                </button>
                <div className="mt-4">
                    <p><strong>Name:</strong> {contact.name}</p>
                    <p><strong>Email:</strong> {contact.email}</p>
                    <p><strong>Phone:</strong> {contact.phone}</p>
                    <p><strong>Message:</strong> {contact.message || "No message available."}</p>
                    <div className="mt-4">
                        <h3 className="text-xl font-semibold mb-2">Connect with:</h3>
                        <p className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faPhone} className="mr-2" />
                            <a href={`tel:${contact.phone}`} className="hover:underline">{contact.phone}</a>
                        </p>
                        <p className="flex items-center mb-2">
                            <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                            <a href={`mailto:${contact.email}`} className="hover:underline">{contact.email}</a>
                        </p>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default ContactDataPage;
