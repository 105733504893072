import React from 'react';
import { useLocation } from "react-router-dom";
import AVNetwork from "../assets/img/Av_network.webp";
import Rentoora from "../assets/img/rentoora.webp";
import RentooraApp from "../assets/RentooraAPP.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faCode } from '@fortawesome/free-solid-svg-icons';
import confetti from 'canvas-confetti';

// Sample card data (you might want to import or fetch this from a service)
const allProjects = [
    {
        id: 1,
        image: AVNetwork,
        title: "React Project",
        description: "This project uses React for the frontend and includes various features.",
        websiteLink: "https://av-network.bhishansah.com.np/",
        technologies: ["React", "Tailwind CSS", "Node.js & Express", "MongoDB"],
    },
    {
        id: 2,
        image: Rentoora,
        title: "Vite Mern App",
        description: "A MERN stack application showcasing real-time data handling.",
        websiteLink: "https://rentoora.bhishansah.com.np/",
        technologies: ["React", "Tailwind CSS", "Node.js & Express", "MongoDB"],
    },
    {
        id: 3,
        image: RentooraApp,
        title: "Mobile App",
        description: "A mobile app built with React Native and Expo.",
        websiteLink: "URL_ADDRESS",
        technologies: [ "React Native", "Expo", "Node.js & Express", "MongoDB"],
      },
    // Add more projects as needed
];

const ProjectPage = ({ darkMode }) => {
    const location = useLocation();
    const { card } = location.state || {};

    React.useEffect(() => {
        // Set page title
        document.title = "Projects | Bhishan Sah - Web Developer Portfolio";
        
        // Set meta tags
        document.querySelector('meta[name="description"]').setAttribute(
            "content", 
            "Explore my web development projects including React applications, MERN stack solutions, and modern web technologies. View live demos and source code."
        );
        
        document.querySelector('meta[name="keywords"]').setAttribute(
            "content", 
            "web projects, React, MERN stack, Tailwind CSS, MongoDB, Node.js, Express, portfolio projects"
        );

        // Confetti effect
        confetti({
            particleCount: 100,
            spread: 70,
            origin: { y: 0.6 }
        });
    }, []);

    return (
        <div className={`${darkMode ? 'bg-gray-900' : 'bg-gray-100'} min-h-screen py-12`}>
            <div className="container px-4 max-w-5xl mx-auto">
                {card ? (
                    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
                        <div className="md:flex">
                            <div className="md:flex-shrink-0">
                                <img className="h-fit w-full object-fit object-top md:w-48" src={card.image} alt={card.title} />
                            </div>
                            <div className="p-8">
                                <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{card.title}</div>
                                <p className="mt-2 text-gray-500 dark:text-gray-400">{card.description}</p>
                                <div className="mt-4">
                                    <span className="text-gray-500 dark:text-gray-400 text-sm font-medium mr-2">Technologies:</span>
                                    {card.technologies.map((tech, index) => (
                                        <span key={index} className="inline-block bg-gray-200 dark:bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-200 mr-2 mb-2">{tech}</span>
                                    ))}
                                </div>
                                <div className="mt-6 flex items-center">
                                    <a
                                        href={card.websiteLink}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
                                        Preview
                                    </a>
                                    <a
                                        href="#"
                                        className="ml-3 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <FontAwesomeIcon icon={faCode} className="mr-2" />
                                        Source Code
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="grid lg:grid-cols-3 gap-6">
                        {allProjects.map((project) => (
                            <div key={project.id} className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
                                <img className="h-52 w-full  object-fill" src={project.image} alt={project.title} />
                                <div className="p-3">
                                    <div className="uppercase tracking-wide text-sm text-indigo-500 font-semibold">{project.title}</div>
                                    <p className="mt-2 text-gray-500 dark:text-gray-400">{project.description}</p>
                                    <div className="mt-4">
                                        {project.technologies.map((tech, index) => (
                                            <span key={index} className="inline-block bg-gray-200 dark:bg-gray-700 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 dark:text-gray-200 mr-2 mb-2">{tech}</span>
                                        ))}
                                    </div>
                                    <div className="mt-6">
                                        <a
                                            href={project.websiteLink}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        >
                                            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-2" />
                                            Preview
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProjectPage;
