import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate('/'); // Navigate to the home page
    };

    return (
        <div className="flex flex-col items-center justify-center mt-16 text-center">
            <h1 className="text-4xl font-bold">Page Not Found</h1>
            <p className="mt-4 text-lg">Sorry, the page you are looking for does not exist.</p>
            <button
                onClick={handleGoHome}
                className="mt-6 bg-blue-500 text-white px-4 py-2 rounded"
            >
                Return to Home Page
            </button>
        </div>
    );
};

export default NotFound;
