import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faPhone, faSignOutAlt, faAddressBook, faCloudUploadAlt, faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ContactDataPage from './ContactDataPage.jsx'; 
import api from '../utils/api'; 
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';

const Dashboard = ({ onLogout, darkMode }) => {
    const [user, setUser] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [showContacts, setShowContacts] = useState(false);
    const [showUploadForm, setShowUploadForm] = useState(false);
    const [uploadData, setUploadData] = useState({
        title: '',
        description: '',
        file: null,
        type: 'image' // or 'video'
    });
    const [isUploading, setIsUploading] = useState(false);
    const [mediaItems, setMediaItems] = useState([]);
    const [showMediaTable, setShowMediaTable] = useState(false);
    const [editingMedia, setEditingMedia] = useState(null);
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        show: false,
        mediaId: null,
        mediaType: '',
        mediaTitle: '',
        isDeleting: false
    });
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [showMobileEditPopup, setShowMobileEditPopup] = useState(false);

    useEffect(() => {
        const userData = localStorage.getItem('user');
        if (userData) {
            setUser(JSON.parse(userData)); 
        }
    }, []);

    const fetchContacts = async () => {
        try {
            const response = await api.get('/auth/contacts');
            setContacts(response.data);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    const updateContactsAfterDelete = (contactId) => {
        setContacts(prevContacts => prevContacts.filter(contact => contact._id !== contactId));
    };

    const handleViewContacts = () => {
        fetchContacts();
        setShowContacts(true);
    };

    const handleClose = () => {
        setShowContacts(false);
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        setIsUploading(true);
        setUploadProgress(0);
        setUploadSuccess(false);

        try {
            const formData = new FormData();
            formData.append('file', uploadData.file);
            formData.append('title', uploadData.title);
            formData.append('description', uploadData.description);
            formData.append('type', uploadData.type);

            const isVideo = uploadData.type === 'video';
            const fileSize = uploadData.file.size;
            const initialProgressLimit = isVideo ? 80 : 85;
            
            const getStepDuration = () => {
                const sizeMB = fileSize / (1024 * 1024);
                if (isVideo) {
                    return sizeMB > 100 ? 3000 : 2000;
                }
                return sizeMB > 5 ? 1500 : 1000;
            };

            const response = await api.post('/media/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * initialProgressLimit) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                }
            });

            if (response.data.success) {
                const startProgress = initialProgressLimit;
                const endProgress = 100;
                const duration = getStepDuration();
                const steps = isVideo ? 20 : 15;
                const increment = (endProgress - startProgress) / steps;
                const stepDuration = duration / steps;

                for (let i = 0; i <= steps; i++) {
                    setTimeout(() => {
                        setUploadProgress(startProgress + (increment * i));
                    }, stepDuration * i);
                }

                setTimeout(() => {
                    setUploadSuccess(true);
                    setTimeout(() => {
                        setShowUploadForm(false);
                        setUploadData({
                            title: '',
                            description: '',
                            file: null,
                            type: 'image'
                        });
                        setUploadSuccess(false);
                    }, isVideo ? 1500 : 1000);
                }, duration);
            }
        } catch (error) {
            console.error('Upload error:', error);
            alert('Upload failed. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const fetchMedia = async () => {
        try {
            const response = await api.get('/media/all');
            setMediaItems(response.data);
        } catch (error) {
            console.error('Error fetching media:', error);
        }
    };

    const handleDeleteMedia = async (id) => {
        const mediaItem = mediaItems.find(item => item._id === id);
        setDeleteConfirmation({
            show: true,
            mediaId: id,
            mediaType: mediaItem.type,
            mediaTitle: mediaItem.title
        });
    };

    const confirmDelete = async () => {
        setDeleteConfirmation(prev => ({ ...prev, isDeleting: true }));
        try {
            await api.delete(`/media/${deleteConfirmation.mediaId}`);
            setMediaItems(prev => prev.filter(item => item._id !== deleteConfirmation.mediaId));
            setDeleteConfirmation({ show: false, mediaId: null, mediaType: '', mediaTitle: '', isDeleting: false });
            toast.success('Media deleted successfully!');
        } catch (error) {
            console.error('Delete error:', error);
            alert('Delete failed. Please try again.');
            setDeleteConfirmation(prev => ({ ...prev, isDeleting: false }));
        }
    };

    const handleUpdateMedia = async (id, updatedData) => {
        try {
            const response = await api.put(`/media/${id}`, updatedData);
            setMediaItems(prev => prev.map(item => 
                item._id === id ? response.data.media : item
            ));
            setEditingMedia(null);
            toast.success('Media updated successfully!');
        } catch (error) {
            console.error('Update error:', error);
            alert('Update failed. Please try again.');
        }
    };

    if (!user) {
        return (
            <div className="flex justify-center items-center h-screen">
                <p className="text-lg">Loading...</p>
            </div>
        );
    }

    return (
        <div className={`min-h-screen ${darkMode ? 'bg-gray-900 text-white' : 'bg-gray-100 text-gray-800'}`}>
            <div className="container mx-auto px-4 py-8">
                <div className={`flex justify-between items-center mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <h2 className="text-2xl lg:text-3xl font-bold font-handwriting capitalize">Dashboard</h2>
                    <button 
                        onClick={onLogout} 
                        className="bg-red-500 text-white lg:px-4 lg:py-2  px-2 py-1 lg:rounded-full rounded hover:bg-red-600 transition duration-200 flex items-center"
                    >
                        <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                        Logout
                    </button>
                </div>
                
                <div className={`mb-8 p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <h3 className="text-2xl font-semibold mb-6 font-handwriting">Personal Information</h3>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faUser} className="text-blue-500 mr-4 text-xl" />
                            <div>
                                <p className="text-sm opacity-70">Name</p>
                                <p className="font-semibold">{user.name}</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 mr-4 text-xl" />
                            <div>
                                <p className="text-sm opacity-70">Email</p>
                                <p className="font-semibold">{user.email}</p>
                            </div>
                        </div>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faPhone} className="text-blue-500 mr-4 text-xl" />
                            <div>
                                <p className="text-sm opacity-70">Mobile No</p>
                                <p className="font-semibold">{user.mobileNo}</p>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className={`p-6 rounded-lg shadow-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                    <h3 className="text-2xl font-semibold mb-6 font-handwriting text-center">Action Section</h3>
                    <div className="flex flex-col lg:flex-row justify-center gap-4">
                        <button 
                            onClick={handleViewContacts} 
                            className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition duration-200 flex items-center"
                        >
                            <FontAwesomeIcon icon={faAddressBook} className="mr-2" />
                            View Contacts
                        </button>
                        <button 
                            onClick={() => setShowUploadForm(true)} 
                            className="px-6 py-3 bg-green-600 text-white rounded-full hover:bg-green-700 transition duration-200 flex items-center"
                        >
                            <FontAwesomeIcon icon={faCloudUploadAlt} className="mr-2" />
                            Upload Media
                        </button>
                        <button 
                            onClick={() => {
                                fetchMedia();
                                setShowMediaTable(true);
                            }} 
                            className="px-6 py-3 bg-purple-600 text-white rounded-full hover:bg-purple-700 transition duration-200 flex items-center"
                        >
                            <FontAwesomeIcon icon={faEye} className="mr-2" />
                            View Media
                        </button>
                    </div>
                </div>
            </div>

            {showContacts && (
                <ContactDataPage 
                    contacts={contacts} 
                    onClose={handleClose} 
                    onDelete={updateContactsAfterDelete} 
                />
            )}

            {showUploadForm && (
                <motion.div 
                    initial={{ y: "100%" }}
                    animate={{ y: 0 }}
                    exit={{ y: "100%" }}
                    transition={{ type: "spring", stiffness: 300, damping: 30 }}
                    className="fixed lg:inset-0 bottom-0  w-full lg:w-1/2 mx-auto flex items-center justify-center z-50"
                >
                    <div className={`lg:p-6 py-2 relative px-4 lg:rounded-lg border-t-4 lg:border-b-4 shadow-gray-300  border-gray-400  rounded-t-lg shadow-lg lg:w-1/2 w-full ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                        <h3 className="text-2xl font-semibold mb-4">Upload Media</h3>
                        {uploadSuccess && (
                            <div className="mb-4 p-3 bg-green-100 text-green-700 lg:rounded">
                                Media uploaded successfully!
                            </div>
                        )}
                        <form onSubmit={handleUpload}>
                            <div className="mb-4">
                                <label className="block mb-2">Title</label>
                                <input
                                    type="text"
                                    value={uploadData.title}
                                    onChange={(e) => setUploadData({...uploadData, title: e.target.value})}
                                    className="w-full p-2 border rounded text-gray-800"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Description</label>
                                <textarea
                                    value={uploadData.description}
                                    onChange={(e) => setUploadData({...uploadData, description: e.target.value})}
                                    className="w-full p-2 border rounded text-gray-800"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">Type</label>
                                <select
                                    value={uploadData.type}
                                    onChange={(e) => setUploadData({...uploadData, type: e.target.value})}
                                    className="w-full p-2 border rounded text-gray-800"
                                >
                                    <option value="image">Image</option>
                                    <option value="video">Video</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-2">File</label>
                                <input
                                    type="file"
                                    accept={uploadData.type === 'image' ? 'image/*' : 'video/*'}
                                    onChange={(e) => setUploadData({...uploadData, file: e.target.files[0]})}
                                    className="w-full p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="flex justify-end gap-2">
                                <button
                                    type="button"
                                    onClick={() => setShowUploadForm(false)}
                                    className="px-4 absolute top-0 right-0 py-3  font-bold text-red-600 hover:text-red-700 rounded"
                                    disabled={isUploading}
                                >
                                    X
                                </button>
                                <button
                                    type="submit"
                                    disabled={isUploading}
                                    className="relative px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 disabled:bg-blue-300 overflow-hidden"
                                >
                                    <div
                                        className="absolute inset-0 bg-blue-800 transition-all duration-300"
                                        style={{ width: `${uploadProgress}%` }}
                                    />
                                    <span className="relative">
                                        {isUploading ? `Uploading ${uploadProgress}%` : 'Upload'}
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </motion.div>
            )}

            {showMediaTable && (
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.3 }}
                    className="fixed lg:inset-0 bottom-0 w-full lg:w-3/4 mx-auto  flex items-center justify-center z-50 overflow-y-auto"
                >
                    <motion.div
                        initial={{ scale: 0.9 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.9 }}
                        transition={{ duration: 0.2 }}
                        className={`lg:p-6 p-2  rounded-lg border-t-4 border-gray-400 rounded-t-lg lg:border-2 w-full lg:w-3/4 mx-auto ${darkMode ? 'bg-gray-800' : 'bg-white'}`}
                    >
                        <div className="flex justify-between items-center mb-4 border-b-2 border-gray-400 pb-2 pr-2">
                            <h3 className="lg:text-2xl text-xl font-bold uppercase">Media Data</h3>
                            <button
                                onClick={() => setShowMediaTable(false)}
                                className="text-2xl lg:text-3xl font-bold text-red-600 hover:text-red-700"
                            >
                                ×
                            </button>
                        </div>
                        <div className="overflow-x-auto h-[80vh] overflow-y-auto">
                            <div className="hidden lg:block"> {/* Desktop Table */}
                                <table className="w-full">
                                    <thead>
                                        <tr className="border-b text-left">
                                            <th className="px-4 py-2">#</th>
                                            <th className="px-4 py-2">Preview</th>
                                            <th className="px-4 py-2">Title</th>
                                            <th className="px-4 py-2">Description</th>
                                            <th className="px-4 py-2">Type</th>
                                            <th className="px-4 py-2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {mediaItems.map((item, index) => (
                                            <tr key={item._id} className="border-b">
                                                <td className="px-4 py-2">{index + 1}</td>
                                                <td className="px-4 py-2">
                                                    {item.type === 'image' ? (
                                                        <img src={item.url} alt={item.title} className="w-16 h-16 rounded object-cover" />
                                                    ) : (
                                                        <video src={item.url} className="w-16 h-16 rounded object-cover" />
                                                    )}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {editingMedia?.id === item._id ? (
                                                        <input
                                                            type="text"
                                                            value={editingMedia.title}
                                                            onChange={e => setEditingMedia({...editingMedia, title: e.target.value})}
                                                            className="border p-1 text-gray-800"
                                                        />
                                                    ) : item.title}
                                                </td>
                                                <td className="px-4 py-2">
                                                    {editingMedia?.id === item._id ? (
                                                        <textarea
                                                            value={editingMedia.description}
                                                            onChange={e => setEditingMedia({...editingMedia, description: e.target.value})}
                                                            className="border p-1 text-gray-800 w-full"
                                                            rows="3"
                                                        />
                                                    ) : item.description}
                                                </td>
                                                <td className="px-4 py-2">{item.type}</td>
                                                <td className="px-4 py-2">
                                                    {editingMedia?.id === item._id ? (
                                                        <div className="flex gap-2">
                                                            <button
                                                                onClick={() => handleUpdateMedia(item._id, {
                                                                    title: editingMedia.title,
                                                                    description: editingMedia.description
                                                                })}
                                                                className="text-green-500 hover:text-green-700"
                                                            >
                                                                Save
                                                            </button>
                                                            <button
                                                                onClick={() => setEditingMedia(null)}
                                                                className="text-gray-500 hover:text-gray-700"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex gap-2">
                                                            <button
                                                                onClick={() => setEditingMedia({
                                                                    id: item._id,
                                                                    title: item.title,
                                                                    description: item.description
                                                                })}
                                                                className="text-blue-500 hover:text-blue-700"
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                            <button
                                                                onClick={() => handleDeleteMedia(item._id)}
                                                                className="text-red-500 hover:text-red-700"
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </div>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            
                            <div className="lg:hidden"> {/* Mobile Cards */}
                                {mediaItems.map((item, index) => (
                                    <div key={item._id} className={`mb-4 p-2 rounded-lg ${darkMode ? 'bg-gray-700' : 'bg-gray-50'}`}>
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center gap-4">
                                                <span className="font-semibold">{index + 1}</span>
                                                {item.type === 'image' ? (
                                                    <img src={item.url} alt={item.title} className="w-6 h-6 rounded object-cover" />
                                                ) : (
                                                    <video src={item.url} className="w-6 h-6 rounded object-cover" />
                                                )}
                                                <p className="text-sm text-left">
                                                    {item.title.length > 10 ? `${item.title.substring(0, 10)}...` : item.title}
                                                </p>
                                            </div>
                                            <div className="flex gap-2">
                                                <button
                                                    onClick={() => {
                                                        setEditingMedia({
                                                            id: item._id,
                                                            title: item.title,
                                                            description: item.description
                                                        });
                                                        setShowMobileEditPopup(true);
                                                    }}
                                                    className="py-0.5 px-2 bg-blue-500 text-white rounded"
                                                >
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteMedia(item._id)}
                                                    className="py-0.5 px-2 bg-red-500 text-white rounded"
                                                >
                                                    <FontAwesomeIcon icon={faTrash} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}

            {deleteConfirmation.show && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className={`p-6 rounded-lg shadow-lg max-w-md w-full ${darkMode ? 'bg-gray-800' : 'bg-white'}`}>
                        <h3 className="text-2xl font-semibold mb-4">Confirm Delete</h3>
                        <p className="mb-4">Are you sure you want to delete this {deleteConfirmation.mediaType}?</p>
                        <p className="mb-6 font-semibold">Title: {deleteConfirmation.mediaTitle}</p>
                        <div className="flex justify-end gap-2">
                            <button
                                onClick={() => setDeleteConfirmation({ show: false, mediaId: null, mediaType: '', mediaTitle: '', isDeleting: false })}
                                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                                disabled={deleteConfirmation.isDeleting}
                            >
                                Cancel
                            </button>
                            <button
                                onClick={confirmDelete}
                                disabled={deleteConfirmation.isDeleting}
                                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 disabled:bg-red-400 disabled:cursor-not-allowed"
                            >
                                {deleteConfirmation.isDeleting ? 'Deleting...' : 'Delete'}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {showMobileEditPopup && editingMedia && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
                    <motion.div
                        initial={{ scale: 0.9, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0.9, opacity: 0 }}
                        className={`w-full relative max-w-md p-6 rounded-lg ${darkMode ? 'bg-gray-800' : 'bg-white'}`}
                    >
                        <h3 className="text-xl font-semibold mb-4">Edit Media</h3>
                        <div className="space-y-4">
                            <div>
                                <label className="block mb-2 font-medium">Title</label>
                                <input
                                    type="text"
                                    value={editingMedia.title}
                                    onChange={e => setEditingMedia({...editingMedia, title: e.target.value})}
                                    className="w-full p-2 border rounded text-gray-800"
                                />
                            </div>
                            <div>
                                <label className="block mb-2 font-medium">Description</label>
                                <textarea
                                    value={editingMedia.description}
                                    onChange={e => setEditingMedia({...editingMedia, description: e.target.value})}
                                    className="w-full p-2 border rounded text-gray-800"
                                    rows="4"
                                />
                            </div>
                            <div className="flex justify-end gap-2 mt-6">
                                <button
                                    onClick={() => {
                                        setShowMobileEditPopup(false);
                                        setEditingMedia(null);
                                    }}
                                    className=" absolute top-0 right-0 px-4 py-2  font-bold text-red-600 hover:text-red-700 rounded"
                                >
                                    x
                                </button>
                                <button
                                    onClick={() => {
                                        handleUpdateMedia(editingMedia.id, {
                                            title: editingMedia.title,
                                            description: editingMedia.description
                                        });
                                        setShowMobileEditPopup(false);
                                    }}
                                    className="px-4 py-2 bg-blue-500 text-white rounded"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </div>
                    </motion.div>
                </div>
            )}
        </div>
    );
};

export default Dashboard;
