import React, { useEffect , useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import { faCode } from '@fortawesome/free-solid-svg-icons';

const Navbar = ({ darkMode, setDarkMode }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); 

    useEffect(() => {
        document.body.classList.toggle('dark', darkMode);
    }, [darkMode]);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleNavigation = (path) => {
        setIsOpen(false);
        navigate(path);
    };

    const isActive = (path) => (location.pathname === path ? 'bg-blue-500/50 font-bold' : '');

    return (
        <nav className={`fixed select-none z-30 top-0 left-0 right-0 lg:px-6 px-2 lg:py-2 py-3 flex justify-between items-center shadow-md backdrop-blur-md ${darkMode ? 'bg-gray-600/70' : 'bg-white/70'}`}>
           
                 <div className="flex items-center gap-2 cursor-pointer " onClick={() => handleNavigation('/')}>
                   <FontAwesomeIcon icon={faCode} className={`lg:text-xl text-md ${darkMode ? 'text-gray-200' : 'text-gray-800'}`} />
                   <h1 className={`font-extrabold lg:text-xl text-md tracking-wider uppercase ${darkMode ? 'bg-gradient-to-r from-gray-200 to-gray-400 text-transparent bg-clip-text' : 'bg-gradient-to-r from-black to-gray-400 text-transparent bg-clip-text'}`}>Bhishan sah</h1>
                 </div>
           
            <div className="hidden md:flex space-x-8">
                <button className={`py-1 px-3 rounded ${isActive('/')}`} onClick={() => handleNavigation('/')}>Home</button>
                <button className={`py-1 px-3 rounded ${isActive('/about')}`} onClick={() => handleNavigation('/about')}>About</button>
                <button className={`py-1 px-3 rounded ${isActive('/projects')}`} onClick={() => handleNavigation('/projects')}>Projects</button>
                <button className={`py-1 px-3 rounded ${isActive('/contact')}`} onClick={() => handleNavigation('/contact')}>Contact</button>
                <button className={`py-1 px-3 rounded ${isActive('/my-blogs')}`} onClick={() => handleNavigation('/my-blogs')}>News</button>
                <button className={`py-1 px-3 rounded ${isActive('/gallery')}`} onClick={() => handleNavigation('/gallery')}>Gallery</button>
            </div>
            <div className="relative flex gap-2 items-center">
                <button 
                    onClick={() => setDarkMode(!darkMode)} 
                    onMouseEnter={() => setShowTooltip(true)} 
                    onMouseLeave={() => setShowTooltip(false)} 
                    className={`px-2 text-md  rounded transition-colors ${darkMode ? ' text-white' : ''}`}
                >
                    <FontAwesomeIcon icon={darkMode ? faSun : faMoon} />
                </button>
                {showTooltip && (
                    <div className={`absolute top-10 right-0 p-1 w-[80px] text-xs text-white bg-black rounded`}>
                        {darkMode ? 'Light Mode' : 'Dark Mode'}
                    </div>
                )}
                <div className="md:hidden text-xl flex items-center justify-center w-8  h-auto">
                    <button onClick={toggleMenu}>
                        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
                    </button>
                </div>
            </div>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0, translateY: -20 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    exit={{ opacity: 0, translateY: -20 }}
                    className={`text-lg font-bold absolute top-12 left-0 flex flex-col gap-2  w-full  p-2  shadow-lg md:hidden ${darkMode ? '  bg-gray-700' : 'text-black  bg-color-white'}`}
                >
                    <button className={`block py-2 px-2 rounded w-full  ${isActive('/')}`} onClick={() => handleNavigation('/')}>Home</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/about')}`} onClick={() => handleNavigation('/about')}>About</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/projects')}`} onClick={() => handleNavigation('/projects')}>Projects</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/contact')}`} onClick={() => handleNavigation('/contact')}>Contact</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/my-blogs')}`} onClick={() => handleNavigation('/my-blogs')}>News</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/gallery')}`} onClick={() => handleNavigation('/gallery')}>Gallery</button>
                    <button className={`block py-2  px-2 rounded w-full ${isActive('/Login')}`} onClick={() => handleNavigation('/Login')}>Login</button>
                </motion.div>
            )}
        </nav>
    );
};

export default Navbar;
